import React from 'react'
import ResultsDisclaimer from './../ResultsDisclaimer/ResultsDisclaimer'
import './StylingHooksSearchResults.css'

class StylingHooksSearchResults extends React.Component {
  render() {

    const { results, isExactMatchFound, hexCode, category, searchType } = this.props.data;
    const isIconsCategory = this.props.data.resultsType === 'icons';
    const stylingHookOrPalette = isIconsCategory ? 'Name' : 'Styling Hook'

    return (
      <div className="styling-hooks-search-results">
        <h2 className="row search-results-heading">Search Results</h2>
        <div className="row">
          {
            results && 
            <ResultsDisclaimer category={category} hexCode={hexCode} isExactMatchFound={isExactMatchFound} searchType={searchType}/>
          }
          <table className="table" id="closest-hooks-table">
            <thead>
              <tr>
                <th scope="col" style={{ width: "5%" }}>#</th>
                <th scope="col" style={{ width: `${isIconsCategory ? '15%' : '30%'}` }}>{stylingHookOrPalette}</th>
                <th scope="col" style={{ width: "12.5%" }}>Code</th>
                <th scope="col" style={{ width: `${isIconsCategory ? '10%' : '12.5%'}` }}>Color</th>
                {
                  !isIconsCategory &&
                  <th scope="col" style={{ width: "40%" }}>Comment</th>
                }
              </tr>
            </thead>
            <tbody>
              {
                results && results.map((data, index) => (
                  <tr key={`row-${index+1}`}>
                    <td>{index+1}</td>
                    <td>{data.stylingHook}</td>
                    <td>{data.colorCode}</td>
                    <td style={{ backgroundColor: data.colorCode }} className="color-code-col"></td>
                    {
                      !isIconsCategory &&
                      <td>{data.comment}</td>
                    }
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default StylingHooksSearchResults
import React from 'react'
import './App.css';

import Header from './components/Header/Header'
import UserInputOptions from './components/UserInputOptions/UserInputOptions'
import HexCodeInput from './components/HexCodeInput/HexCodeInput'
import RgbInput from './components/RgbInput/RgbInput'
import UserStylingContextInput from './components/UserStylingContextInput/UserStylingContextInput'
import StylingHooksSearchResults from './components/StylingHooksSearchResults/StylingHooksSearchResults'

class App extends React.Component {

  constructor(props) {
    super(props)
  
    this.state = {
      currentInputOption: "HexCode",
      hexCodeResults: {},
      rgbResults: {},
      categoryResults: {}
    }
  }

  getUserOption(option) {
    this.setState({
      currentInputOption: option
    })
  }

  populateHexCodeResults(data) {
    this.setState({
      searchedHexCode: data.hexCode,
      hexCodeResults: data
    });
  }
  
  populateRgbResults(data) {
    this.setState({
      searchedRgb: data.rgb,
      rgbResults: data
    });
  }

  populateCategoryResults(data) {
    this.setState({
      searchedCategory: data.category,
      categoryResults: data
    });
  }

  render() {
    const currentInputOption = this.state.currentInputOption;
    return (
      <div className="App">
        <div className="container">
          <Header />
          <div className="main">
            <UserInputOptions onSubmit={ data => this.getUserOption(data) }/>
            <div style={{ display: currentInputOption === 'HexCode' ? 'block' : 'none' }}>
              <HexCodeInput onSubmit={ data => this.populateHexCodeResults(data) } />
              <StylingHooksSearchResults data={this.state.hexCodeResults} />
            </div>
            <div style={{ display: currentInputOption === 'RGB' ? 'block' : 'none' }}>
              <RgbInput onSubmit={ data => this.populateRgbResults(data) } />
              <StylingHooksSearchResults data={this.state.rgbResults} />
            </div>
            <div style={{ display: currentInputOption === 'StylingContext' ? 'block' : 'none' }}>
              <UserStylingContextInput onSubmit={ data => this.populateCategoryResults(data) } />
              <StylingHooksSearchResults data={this.state.categoryResults} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default App

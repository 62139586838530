import React from 'react'
import './RgbInput.css'
import { rgbToHex } from './../../utility/deltaE-algo.js'
import { fetchStylingHooksByHexCodeAndCategory } from './../../utility/styling-hooks-data.js';
import UserStylingContextInput from './../UserStylingContextInput/UserStylingContextInput'

class RgbInput extends React.Component {

  constructor(props) {
    super(props)
  
    this.rRef = React.createRef();
    this.gRef = React.createRef();
    this.bRef = React.createRef();

    this.state = {
      value: '',
      isValidHexCode: false,
      results: []
    }
  }

  resetRgbValuesIfOutOfRange() {
    this.rRef.current.value = this.rRef.current.value > 255
      ? 255 : (this.rRef.current.value < 0 ? 0 : this.rRef.current.value);
    this.gRef.current.value = this.gRef.current.value > 255
      ? 255 : (this.gRef.current.value < 0 ? 0 : this.gRef.current.value);
    this.bRef.current.value = this.bRef.current.value > 255
      ? 255 : (this.bRef.current.value < 0 ? 0 : this.bRef.current.value);
  }
  

  handleOnChange(e) {
    e.preventDefault();
    this.resetRgbValuesIfOutOfRange();

    const r = Number(this.rRef.current.value ? this.rRef.current.value : -1);
    const g = Number(this.gRef.current.value ? this.gRef.current.value : -1);
    const b = Number(this.bRef.current.value ? this.bRef.current.value : -1);

    let isValidColorCode = false;
    let hexCodeForRgb = '';
    let results = {};

    if (r < 0 || g < 0 || b < 0) {
      isValidColorCode = false;
      this.props.onSubmit({
        resultsType: 'Not Valid',
        results: null,
        rgb: [r, g, b]
      });
    } else {
      hexCodeForRgb = rgbToHex(r, g, b).toUpperCase();
      results.rgb = [r, g, b];
      isValidColorCode = true;
    }

    this.setState({
      value: hexCodeForRgb,
      isValidHexCode: hexCodeForRgb,
      isValidColorCode
    }, () => { this.emitResults(); } );
  }

  populateCategoryResults(data) {
    this.setState({
      searchedCategory: data.category,
      categoryResults: data.results
    }, () => {
      this.emitResults();
    });
  }

  emitResults() {
    if (!this.state.isValidHexCode || !this.state.searchedCategory) {
      this.props.onSubmit([]);  
      return;
    }
    const results = fetchStylingHooksByHexCodeAndCategory(this.state.value, this.state.searchedCategory);
    this.props.onSubmit(results);
  }

  render() {
    const backgroundColorValue = this.state.isValidHexCode ? `${this.state.value}` : "";

    return (
      <form>
        <div className="form-group row rgb-section">
          <div className="col-xs-3">
            <label htmlFor="rgb-code" className="rgb-label">Enter RGB values (All fields mandatory)</label>
            <div className="input-group">
              <input type="number"
                className="form-control"
                placeholder="255"
                id="rgb-R"
                name="r"
                ref={this.rRef}
                onChange={ e => this.handleOnChange(e) } />
              <input type="number"
                className="form-control"
                placeholder="255"
                id="rgb-G"
                name="g"
                ref={this.gRef}
                onChange={ e => this.handleOnChange(e) } />
              <input type="number"
                className="form-control"
                placeholder="255"
                id="rgb-B"
                name="b"
                ref={this.bRef}
                onChange={ e => this.handleOnChange(e) } />
              <span className={`${ this.state.isValidHexCode ? "searched-color" : "" }`}
                style={{ backgroundColor : backgroundColorValue }}>
              </span>
              {
                this.state.isValidHexCode &&
                <span className="rgb-to-hexCode-section">
                  { this.state.value }
                </span>
              }
            </div>
          </div>
        </div>
        <div className="rgb-usage-context-section">
          <strong style={{ paddingLeft: "40px" }}>AND</strong>
          <UserStylingContextInput title="Select the usage context:" onSubmit={ data => this.populateCategoryResults(data) } />
        </div>
      </form>  
    )
  }
}

export default RgbInput
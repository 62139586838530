import { hexToRgb, proximity } from './deltaE-algo.js'

const systemStylingHooks = require('./../../node_modules/@salesforce-ux/design-system/design-tokens/dist/colors.raw.json')
const referenceStylingHooks = require('./../../node_modules/@salesforce-ux/design-system/design-tokens/dist/palettes.raw.json')
const colorAliases = referenceStylingHooks.aliases
const mappedStylingHooks = require('./../data/global-mapped-hooks.raw.json')
const sldsRecommendedIconColors = require('./../data/slds-icon-colors.raw.json').props

const refineStylingHooksData = (props = {}) => {
  const propsData = [];
    Object.keys(props).forEach(key => {
      propsData.push({
        stylingHook: `--slds-g-${key}`,
        colorCode: (props[key].value || '').toUpperCase(),
        cssProperties: props[key].cssProperties,
        comment: props[key].comment,
        category: props[key].category || 'NA'
      });
    });
  return propsData;
};

export const systemHooksProps = () => {
  return refineStylingHooksData(systemStylingHooks.props);
};

export const refereceHooksProps = () => {
  return refineStylingHooksData(referenceStylingHooks.props);
};

const mappedStylingHooksProps = () => {
  const rawProps = { "props": {} };
  const resultProps = {};
  const mappedHooksProps = mappedStylingHooks.props;
  Object.keys(mappedHooksProps)
    .forEach(key => {
      Object.keys(mappedHooksProps[key])
        .forEach(colorKey => {
          const mappedKeyHooks = mappedHooksProps[key];
          resultProps[colorKey] = {};
          resultProps[colorKey] = mappedKeyHooks[colorKey];
          resultProps[colorKey].category = key;
        });
    });
  rawProps.props = resultProps;
  return refineStylingHooksData(rawProps.props);
};

const fetchMappedStylingHooks = (category = null) => {
  const mappedStylingHooks = mappedStylingHooksProps();
  
  if (category) {
    const mappedCategoryStylingHooks = mappedStylingHooks
      .filter(hook => hook.category.toLowerCase() === category.toLowerCase());
    return mappedCategoryStylingHooks;
  } else {
    return mappedStylingHooks;
  }
};

export const fetchIconColors = () => {
  const iconColors = sldsRecommendedIconColors.iconColors;
  const results = [];
  iconColors.forEach(key => {
    const iconColorAsPaletteKey = `PALETTE_${key.replace(/-/g, '_').toUpperCase()}`;
    const aliasColor = Object.keys(colorAliases).find(color => color === iconColorAsPaletteKey);
    const aliasColorValue = aliasColor ? colorAliases[aliasColor].value.toUpperCase() : 'NA';
    results.push({
      stylingHook: key,
      colorCode: aliasColorValue
    });
  });
  return results;
};

export const allHooks = () => [...systemHooksProps(), ...refereceHooksProps()];

export const fetchExactHooks = (targetHexCode = '', hooksArr = []) => {
  const exactlyMatchedHooks = [];
  targetHexCode = targetHexCode.replace('#', '').toUpperCase();
  const distance = 0;
  hooksArr
    .forEach(data => {
      const colorCode = (data.colorCode).replace('#', '').toUpperCase();
      if (targetHexCode === colorCode) {
        exactlyMatchedHooks.push({
          ...data,
          ...{ distance }
        });
      }
    });
  return exactlyMatchedHooks;
};

export const fetchClosestHooks = (hexCode = '') => {
  hexCode = hexCode.replace('#', '');
  const closestMatchedHooks = [];
  const rgbOfHexCode = hexToRgb(hexCode);
  allHooks()
    .forEach(data => {
      const propsHexCode = hexToRgb(data.colorCode.replace('#', ''));
      const distance = parseFloat(proximity(rgbOfHexCode, propsHexCode)).toFixed(2);
      closestMatchedHooks
        .push({
          ...data,
          ...{ distance }
        });
    });
  
  closestMatchedHooks.sort((e1, e2) => e1.distance - e2.distance);
  return closestMatchedHooks;
};

export const fetchStylingHooksForGivenHexCode = (hexCode = '') => {
  hexCode = hexCode.replace('#', '');
  
  // First check for exact matched hooks
  const searchedHexCode = `#${hexCode.replace('#', '').toUpperCase()}`;

  if (hexCode.length !== 6) {
    return {
      resultsType: 'Not Valid',
      results: null
    };
  }

  // First  - Check if the EXACT color code is present in the System Hooks
  const exactHooksInSystemHooks = fetchExactHooks(hexCode, systemHooksProps());
  if (exactHooksInSystemHooks.length !== 0) {
    return {
      resultsType: 'Exact',
      results: exactHooksInSystemHooks,
      hexCode: searchedHexCode
    };
  }

  // Second - If First step fails, Check for the EXACT color code is present in the Reference Hooks
  const exactHooksInReferenceHooks = fetchExactHooks(hexCode, refereceHooksProps());
  if (exactHooksInReferenceHooks.length !== 0) {
    return {
      resultsType: 'Exact',
      results: exactHooksInReferenceHooks,
      hexCode: searchedHexCode
    };
  }

  // Third - No Exact Hooks available, Get the closest hooks using Delta-E algorithm
  const closestMatchedHooks = fetchClosestHooks(hexCode);
  return {
    resultsType: 'Closest',
    results: closestMatchedHooks,
    hexCode: searchedHexCode
  };
};

export const fetchStylingHooksByCategory = (category) => {
  if (category === 'icons') {
    return {
      resultsType: 'icons',
      category: 'Icons',
      results: fetchIconColors(),
      searchType: 'full'
    };
  }
  const mappedHooksByCategory = fetchMappedStylingHooks(category);
  return {
    resultsType: 'Category',
    results: mappedHooksByCategory
      .filter(hook => hook.category.toLowerCase() === category.toLowerCase()),
    category: category,
    searchType: 'full'
  };
};

export const findIconColorByHexCode = (hexCode) => {
  if (!hexCode) {
    return;
  }
  hexCode = `#${hexCode.replace('#', '')}`;
  const results = fetchIconColors();
  let searchResults = results.filter(icon => icon.colorCode.toUpperCase() === hexCode.toUpperCase());
  let isExactMatchFound = true;
  if (searchResults.length === 0) {
    hexCode = hexCode.replace('#', '');
    results.forEach(data => {
      const iconHexCodeToRgb = hexToRgb(data.colorCode.replace('#', ''));
      const searchedhexCodeToRgb = hexToRgb(hexCode.replace('#', ''));
      data.distance = parseFloat(proximity(searchedhexCodeToRgb, iconHexCodeToRgb)).toFixed(2);
      searchResults.push(data);
    });
    searchResults.sort((i1, i2) => i1.distance - i2.distance);
    isExactMatchFound = false;
  }

  return {
    resultsType: 'icons',
    category: 'Icons',
    results: searchResults,
    isExactMatchFound,
    hexCode: `#${hexCode.replace('#', '')}`,
    searchType: 'specific'
  }
};

export const fetchStylingHooksByHexCodeAndCategory = (hexCode, category) => {
  if (!hexCode || !category) {
    return;
  }

  if (category.toLowerCase() === 'icons') {
    return findIconColorByHexCode(hexCode);
  }

  let resultsToDisplay = [];
  let isExactMatchFound = false;
  const mappedHooksByCategory = fetchMappedStylingHooks(category);
  const mappedHooksWithExactHexCode = fetchExactHooks(hexCode, mappedHooksByCategory);

  if (mappedHooksWithExactHexCode.length !== 0) {
    resultsToDisplay = mappedHooksWithExactHexCode;
    isExactMatchFound = true;
  } else {
    const searchResults = [];
    mappedHooksByCategory.forEach(data => {
      const iconHexCodeToRgb = hexToRgb(data.colorCode.replace('#', ''));
      const searchedhexCodeToRgb = hexToRgb(hexCode.replace('#', ''));
      data.distance = parseFloat(proximity(searchedhexCodeToRgb, iconHexCodeToRgb)).toFixed(2);
      searchResults.push(data);
    });
    searchResults.sort((i1, i2) => i1.distance - i2.distance);
    resultsToDisplay = searchResults;
  }

  return {
      category: category.charAt(0).toUpperCase() + category.slice(1),
      isExactMatchFound,
      results: resultsToDisplay,
      hexCode: hexCode.includes('#') ? hexCode : `#${hexCode}`,
      searchType: 'specific'
    }
};

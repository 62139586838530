import React from 'react'
import './UserStylingContextInput.css';

import { fetchStylingHooksByCategory } from './../../utility/styling-hooks-data.js';

class UserStylingContextInput extends React.Component {

  handleOnChange(e) {
    const category = e.target.value;
    if (category === 'none') {
      this.props.onSubmit([]);
    } else {
      const results = fetchStylingHooksByCategory(category);
      this.props.onSubmit(results);
    }
  }

  render() {
    const title = this.props.title || "Please select the styling category:";
    return (
      <div className="row user-context-section">
        <label htmlFor="user-context" className="user-context-form-label">{title}</label>
        <select className="user-context-form-select"
          id="user-context"
          name="user-style-context"
          onChange={ e => this.handleOnChange(e) }>
          <option value="none" hidden defaultValue>Select one...</option>
          <option value="link">Link</option>
          <option value="error">Error</option>
          <option value="border">Border</option>
          <option value="success">Success</option>
          <option value="warning">Warning</option>
          <option value="brand">Brand</option>
          <option value="neutral">Neutral</option>
          <option value="icons">Icons (Standard or Action)</option>
        </select>
      </div>
    )
  }
}

export default UserStylingContextInput
import React, { Component } from 'react'
import './HexCodeInput.css'
import { fetchStylingHooksByHexCodeAndCategory } from './../../utility/styling-hooks-data.js';
import UserStylingContextInput from './../UserStylingContextInput/UserStylingContextInput'

class HexCodeInput extends Component {

  constructor(props) {
    super(props) 
    this.state = {
       value: '',
       isValidHexCode: false,
       searchedCategory: '',
       categoryResults: {}
    }
  }

  handleOnChange(e) {
    e.preventDefault();
    let isValidColor = true;
    let { value } = e.target;
    value = value.replace(/[^0-9a-zA-Z]/ig, '');

    isValidColor = value && value.length === 6;

    this.setState({
      value: value.toUpperCase(),
      isValidHexCode: isValidColor
    }, () => {
      this.emitResults()
    });
  }

  populateCategoryResults(data) {
    this.setState({
      searchedCategory: data.category,
      categoryResults: data.results
    }, () => {
      this.emitResults();
    });
  }

  emitResults() {
    if (!this.state.isValidHexCode || !this.state.searchedCategory) {
      this.props.onSubmit([]);  
      return;
    }
    const results = fetchStylingHooksByHexCodeAndCategory(this.state.value, this.state.searchedCategory);
    this.props.onSubmit(results);
  }

  render() {
    const backgroundColorValue = this.state.isValidHexCode ? `#${this.state.value}` : "";
    return (
      <form>
        <div className="form-group row hex-code-section">
          <div className="col-xs-2">
            <label htmlFor="hex-code" className="hex-code-label">Enter Hex Code (6 Characters Mandatory)</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">#</span>
              </div>
              <input id="hexCodeSearchInput"
                type="text"
                className="form-control"
                placeholder="747474"
                name="hexCode"
                value={ this.state.value }
                onChange={ e => this.handleOnChange(e) }
                maxLength={6} />
              <span className={`${ this.state.isValidHexCode ? "searched-color" : "" }`}
                style={{ backgroundColor : backgroundColorValue }}>
              </span>
            </div>
          </div>
        </div>
        <div className="hex-code-usage-context-section">
          <strong style={{ paddingLeft: "40px" }}>AND</strong>
          <UserStylingContextInput title="Select the usage context:" onSubmit={ data => this.populateCategoryResults(data) } />
        </div>
      </form>
    )
  }
}

export default HexCodeInput
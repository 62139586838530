import React, { Component } from 'react'
import './UserInputOptions.css'

class UserInputOptions extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       selectedOption: 'HexCode'
    }
  }
  
  handleOnChange(e) {
    const { value } = e.target;
    this.setState({
      selectedOption: value
    });

    this.props.onSubmit(value);
  }

  render() {
    return (
      <div className="main-user-input">
        <div className="row">
          <p>Choose your input type:</p>
        </div>

        <form name="optionForm" className="option-form">
          <div className="row">
            <div className="col-2">
              <label className="radio-inline">
                <input type="radio" name="option" value="HexCode" className="radio-option-input"
                  checked={this.state.selectedOption === 'HexCode'}
                  onChange={ (e) => this.handleOnChange(e) } />
                Hex Code
              </label>
            </div>
            <div className="col-2">
              <label className="radio-inline">
                <input type="radio" name="option" value="RGB" className="radio-option-input"
                  checked={this.state.selectedOption === 'RGB'}
                  onChange={ (e) => this.handleOnChange(e) } />
                RGB
              </label>
            </div>
            <div className="col-3">
              <label className="radio-inline">
                <input type="radio" name="option" value="StylingContext" className="radio-option-input"
                  checked={this.state.selectedOption === 'StylingContext'}
                  onChange={ (e) => this.handleOnChange(e) } />
                Styling Context
              </label>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default UserInputOptions

import React from 'react'
import './Header.css'

export default function Header() {
  return (
    <div className="jumbotron">
      <h1>SLDS Styling Hooks Finder</h1>
      <p>For any given color (Hex or RGB) and the usage context, displays the possible SLDS Styling Hook(s)</p>
      {/* <p>The search engine uses <a href="https://www.viewsonic.com/library/creative-work/what-is-delta-e-and-why-is-it-important-for-color-accuracy/" target="_blank" rel="noreferrer">Delta-E algorithm</a> to calculate the distance between two RGB values.</p> */}
      <p><strong>Source:</strong> <a href="https://salesforce.quip.com/cM8DASYoGM8I" target="_blank" rel="noreferrer">SLDS Styling Hooks</a> and <a href="https://docs.google.com/presentation/d/1sZfkUjVIgdvCFac8O3FbLimkUSw9QeVJ8AgZ4fg4QlU/edit#slide=id.g13a86adcae5_0_0" target="_blank" rel="noreferrer">SLDS Icon colors</a></p>
    </div>
  )
}

import React from 'react'
import './ResultsDisclaimer.css'

class ResultsDisclaimer extends React.Component {

  render() {
    const { isExactMatchFound, hexCode, category, searchType } = this.props;
    const isForIcons = category === 'Icons';
    const isFullSearch = searchType === 'full';
    return (
      <div>
        {
          !isForIcons && 
          <div className="results-disclaimer">
            {
              !isFullSearch && !isExactMatchFound &&
              <React.Fragment>
                <p className="disclaimer">Note:</p>
                <p>No styling hook was found for color code <span className="font-weight-bold">{hexCode}</span> in the <span className="font-weight-bold capitalize">{category}</span> context. Displaying all the styling hooks that are recommended for <span className="font-weight-bold capitalize">{category}</span>.</p>
              </React.Fragment>
            }
            {
              isFullSearch &&
              <React.Fragment>
                <p className="disclaimer">Note:</p>
                <p>
                  Displaying all the styling hooks that are recommended for <span className="font-weight-bold capitalize">{category}</span>.
                </p>
              </React.Fragment>
            }
          </div>
        }
        {
          isForIcons && 
          <div className="results-disclaimer">
            <p className="disclaimer">Note:</p>
            {
              !isFullSearch && !isExactMatchFound &&
              <p>Searched color <span className="font-weight-bold">{hexCode}</span> is not part of the recommended <span className="font-weight-bold">SLDS Icon colors</span>. Please choose one from the below results.</p>
            }
            {
              !isFullSearch && isExactMatchFound &&
              <p>Searched color <span className="font-weight-bold">{hexCode}</span> is part of the recommended <span className="font-weight-bold">SLDS Icon colors</span>.</p>
            }
            {
              isFullSearch &&
              <p>Following are the recommended colors for <span className="font-weight-bold">Icons in SLDS</span>.</p>
            }
          </div>
        }
      </div>
    )
  }
}

export default ResultsDisclaimer